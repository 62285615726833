_context.invoke(function(Nittro) {
    var builder = new Nittro.DI.ContainerBuilder({
        "params": {},
        "extensions": {
            "forms": "Nittro.Forms.Bridges.FormsDI.FormsExtension()",
            "ajax": "Nittro.Ajax.Bridges.AjaxDI.AjaxExtension()",
            "page": "Nittro.Page.Bridges.PageDI.PageExtension()",
            "flashes": "Nittro.Flashes.Bridges.FlashesDI.FlashesExtension()",
            "checklist": "Nittro.Extras.CheckList.Bridges.CheckListDI.CheckListExtension()",
            "dropzone": "Nittro.Extras.DropZone.Bridges.DropZoneDI.DropZoneExtension()",
            "keymap": "Nittro.Extras.Keymap.Bridges.KeymapDI.KeymapExtension()"
        },
        "services": {
            "tabs": "App.Tabs()!"
        },
        "factories": {}
    });

    this.di = builder.createContainer();
    this.di.runServices();

});